import React, {useContext, useEffect, useRef, useState} from 'react';
import { toast } from 'react-toastify';

import AuthService from 'services/AuthService';
import UserService from 'services/UserService';
import {Button, Input, InputErrorMessage, InputWrapper, Label, MaskInput} from 'styles/Form';
import {ChevronLeft, Smartphone, Unlock} from 'react-feather';
import * as S from '../styles';
import { RenderIf } from 'components/layout';
import UserContext from 'context/user';
import PhoneAuthResendCode from '../PhoneAuthResendCode/PhoneAuthResendCode';
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "../../../layout/modal/Modal";
import {CONSTANTS} from "../../../../config/constants";
import {Text} from "../../../../styles/Commons";

const CODE_SIZE = 6;

enum STEP_VIEW {
	PHONE_VIEW = 'PHONE_VIEW',
	CODE_VIEW = 'CODE_VIEW',
	CODE_PASSWORD_VIEW = 'CODE_PASSWORD_VIEW',
	SELECT_FLOW_VIEW = 'SELECT_FLOW_VIEW',
	PASSWORD_ACCESS_VIEW = 'PASSWORD_ACCESS_VIEW',
	EMAIL_VIEW = 'EMAIL_VIEW',
	REGISTER_PASSWORD_VIEW = 'REGISTER_PASSWORD_VIEW'
}

const PhoneAuth = ({ showBackButton = true, loginSuccessFn, changeTypeFn, backFn, registerFn }: any) => {
	const { setState } = useContext(UserContext);

	const [currentView, setCurrentView] = useState(STEP_VIEW.PHONE_VIEW);
	const [isLoading, setIsLoading] = useState(false);
	const [phone, setPhone] = useState({ value: '', hasError: false });
	const [code, setCode] = useState({ value: '', hasError: false });
	const [password, setPassword] = useState({ value: '', hasError: false });
	const [apikey, setApiKey] = useState<string>('');
	const [cookie, setCookie] = useState<any>(null);
	const [requestAttempt, setRequestAttempt] = useState<number>(0);
	const [captchaToken, setCaptchaToken] = useState('');
	const captchaRef = useRef(null);
	const [email, setEmail] = useState({ value: '', hasError: false });
	const [newPassword, setNewPassword] = useState({ value: '', hasError: false });
	const [confirmPassword, setConfirmPassword] = useState({ value: '', hasError: false });
	const [passwordForm, setPasswordForm] = useState({ invalid: true });

	useEffect(() => {
		const isFormInvalid = (!newPassword.value || newPassword.hasError || !confirmPassword.value || confirmPassword.hasError);
		setPasswordForm({ invalid: isFormInvalid });
	}, [newPassword, confirmPassword]);

	const changePhone = (ev: any) => {
		ev.persist();
		const cleanValue = ev.target.value.replace(/\D/g,'').substring(0, 11);
		setPhone(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < 11,
			value: cleanValue
		}));
	}

	const changeNewPassword = (ev: any) => {
		ev.persist();
		setNewPassword(() => ({
			hasError: ev.target.value.length < 6,
			value: ev.target.value
		}));
	};

	const changeConfirmPassword = (ev: any) => {
		ev.persist();
		setConfirmPassword(() => ({
			hasError: ev.target.value.length < 6 || newPassword.value !== ev.target.value,
			value: ev.target.value
		}));
	};

	const goToPasswordView = (step: any) => {
		setCode({ value: '', hasError: false });
		setPassword({ value: '', hasError: false });
		if (STEP_VIEW.SELECT_FLOW_VIEW && apikey && apikey.length > 0) {
			step = STEP_VIEW.PHONE_VIEW;
		}
		setCurrentView(() => step);
	}

	const selectFlowView = (ev: any) => {
		const params = { phone: phone.value };
		setCode({ value: '', hasError: false });
		setIsLoading(true);
		setApiKey('');
		AuthService.hasPassword(params)
			.finally(() => setIsLoading(false))
			.then(({data}) => {
				if (data && data.apiKey) {
					localStorage.setItem('USER_PHONE', `${phone.value}|||${data.apiKey}|||${data.sessionId}`);
					setApiKey(data.apiKey);
					setCookie(`SESSION_ID=${data.sessionId};`);
					validatePhone(ev)
					return;
				}
				setCurrentView(() => STEP_VIEW.SELECT_FLOW_VIEW)
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const validatePhone = (ev: any) => {
		ev.preventDefault();
		sendCode();
	}

	const changeCode = (ev: any) => {
		ev.persist();
		const cleanValue = ev.target.value.replace(/[^0-9]/g, '').substring(0, CODE_SIZE);
		setCode(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < CODE_SIZE,
			value: cleanValue
		}));
	}

	const changeEmail = (ev: any) => {
		ev.persist();
		setEmail(() => ({
			hasError: ev.target.value.length === 0 || !ev.target.value.match(CONSTANTS.VALID_EMAIL_REGEX),
			value: ev.target.value.trim()
		}));
	}

	const changePassword = (ev: any) => {
		ev.persist();
		setPassword(() => ({
			hasError: ev.target.value.length < 6,
			value: ev.target.value
		}));
	}

	const sendCode = () => {
		const params = { phone: phone.value };
		setCode({ value: '', hasError: false });
		AuthService.sendWhatsappAuthCode(params)
			.then(({ data }: any) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				setCurrentView(() => STEP_VIEW.CODE_VIEW)
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const resendCode = () => {
		const params = { phone: phone.value };
		setCode({ value: '', hasError: false });
		AuthService.resendWhatsappAuthCode(params)
			.then(({ data }: any) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				setCurrentView(() => STEP_VIEW.CODE_VIEW)
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const resendCodePassword = () => {
		setCode({ value: '', hasError: false });
		resetRecaptcha();
		if (email.value) {
			validateEmail();
			return;
		}
		UserService.resendWhatsappRegisterCode(phone.value, apikey)
			.then(({ data }: any) => {
				toast(`Código reenviado com sucesso`, { type: toast.TYPE.SUCCESS });
				setApiKey(data);
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const login = (ev: any) => {
		ev.preventDefault();
		setRequestAttempt(requestAttempt + 1);
		if (requestAttempt > 2 && !captchaToken) {
			toast(`Valide o captcha para confirmar a solicitação.`, { type: toast.TYPE.WARNING });
			return;
		}
		setIsLoading(() => true);
		if (AuthService.isAuthenticated()) {
			getUserData(AuthService.getAccessToken());
			return;
		}
		let key = code.value;
		if (password.value && !password.hasError) {
			key = password.value;
		}
		const params = { phone: phone.value };
		AuthService.login(phone.value, key, 'PHONE', captchaToken)
			.finally(() => resetRecaptcha())
			.then(({ data: token }: any) => {
				setToken(token);
				AuthService.removeCodeSms(params).finally(() => setIsLoading(() => false));
			})
			.catch(({ data }: any) => {
				toast(data.detail, { type: toast.TYPE.ERROR });
				setIsLoading(() => false);
			});
	}

	const setToken = async (token: any) => {
		await AuthService.setToken(token);
		getUserData(token.access_token);
	}

	const getUserData = (accessToken: string) => {
		AuthService.getUserData(accessToken)
			.then(({ data }: any) => {
				AuthService.setUserData(data);
				setUserOnContext(data);
				if (!localStorage.getItem('IS_DATA_COMPLETED')) {
					localStorage.setItem('IS_DATA_COMPLETED', data.isDataCompleted);
				}
				loginSuccessFn('PHONE');
			})
			.catch((err: any) => console.log(err))
			.finally(() => setIsLoading(() => false));
	}

	const setUserOnContext = ((user: any) => {
		setState({
			isLogged: true,
			firstName: user.firstName,
			lastName: user.lastName,
			photoUrl: user.photoUrl,
			username: user.username,
			isDataCompleted: user.isDataCompleted,
		});
	})

	const backHandler = () => {
		const actions: Partial<Record<STEP_VIEW, () => void>> = {
			[STEP_VIEW.PHONE_VIEW]: () => backFn(),
			[STEP_VIEW.SELECT_FLOW_VIEW]: () => setCurrentView(STEP_VIEW.PHONE_VIEW),
			[STEP_VIEW.CODE_VIEW]: () => goToPasswordView(STEP_VIEW.SELECT_FLOW_VIEW),
			[STEP_VIEW.PASSWORD_ACCESS_VIEW]: () => goToPasswordView(STEP_VIEW.SELECT_FLOW_VIEW),
		};
		(actions[currentView] ?? (() => setCurrentView(STEP_VIEW.PHONE_VIEW)))();

	}

	const validateCode = () => {
		setRequestAttempt(requestAttempt + 1);
		if (requestAttempt > 2 && !captchaToken) {
			toast(`Valide o captcha para confirmar a solicitação.`, { type: toast.TYPE.WARNING });
			return;
		}
		const params: any = { code: code.value, email: null };
		if (email.value) {
			params.email = email.value;
		}
		setCode({ value: '', hasError: false });
		AuthService.validateCodeForPassword(params, apikey, captchaToken, cookie)
			.finally(() => resetRecaptcha())
			.then(({ data }: any) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				setCurrentView(STEP_VIEW.REGISTER_PASSWORD_VIEW);
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const resetRecaptcha = () => {
		if (captchaToken && captchaToken.length > 0) {
			setCaptchaToken('');
			//@ts-ignore
			captchaRef?.current?.reset();
		}
	}

	const goToViewAccessPassword = () => {
		if (!apikey) {
			setCurrentView(STEP_VIEW.PASSWORD_ACCESS_VIEW);
			return;
		}
		setCurrentView(STEP_VIEW.EMAIL_VIEW);
	};

	const validateEmail = () => {
		if (email.hasError || !email.value) {
			return;
		}
		setIsLoading(true);
		const params = { email: email.value, phone: phone.value };
		AuthService.validateEmail(apikey, cookie, params)
			.finally(() => setIsLoading(false))
			.then(() => {
				toast('Enviamos o código para seu e-mail!', { type: toast.TYPE.SUCCESS });
				setCurrentView(STEP_VIEW.CODE_PASSWORD_VIEW);
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }));
	};

	const validatePassword = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);
		const payload = {
			password: newPassword.value,
			confirmPassword: confirmPassword.value,
		}
		AuthService.registerPassword(payload, cookie, apikey)
			.then(({ data }: any) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				setCurrentView(STEP_VIEW.PASSWORD_ACCESS_VIEW);
				AuthService.removeCodeSms({ phone: phone.value }).then(() => {});
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => {
				setIsLoading(() => false);
				setRequestAttempt(0);
			});
	};

	return (
		<>
			<S.Header>
				<RenderIf condition={showBackButton || [STEP_VIEW.CODE_VIEW, STEP_VIEW.SELECT_FLOW_VIEW, STEP_VIEW.PASSWORD_ACCESS_VIEW, STEP_VIEW.CODE_PASSWORD_VIEW].includes(currentView)}>
					<S.ReturnButton onClick={backHandler}>
						<ChevronLeft size="1rem" />
					</S.ReturnButton>
				</RenderIf>
				<S.Title>{currentView === STEP_VIEW.REGISTER_PASSWORD_VIEW ? 'Cadastre sua senha' : 'Acesse sua conta'}</S.Title>
			</S.Header>

			<RenderIf condition={currentView === STEP_VIEW.PHONE_VIEW}>
				<InputWrapper hasError={phone.hasError}>
					<Label>Telefone</Label>
					<MaskInput
						id="phone"
						mask="(99) 99999-9999"
						value={phone.value}
						maskPlaceholder={null}
						placeholder="(99) 99999-9999"
						onChange={changePhone}
					/>
					<InputErrorMessage>Campo inválido</InputErrorMessage>
				</InputWrapper>
				<Button
					width="100%"
					margin="2.25rem 0 0"
					loading={isLoading}
					onClick={selectFlowView}
					disabled={phone.value.length === 0 || phone.hasError}>Acessar conta</Button>
				<S.SmallTextLinkColored onClick={changeTypeFn}>Entrar com e-mail</S.SmallTextLinkColored>
				<S.SmallTextLinkColored onClick={registerFn}>Cadastre-se</S.SmallTextLinkColored>
			</RenderIf>

			<RenderIf condition={currentView === STEP_VIEW.SELECT_FLOW_VIEW}>
				<Label>Como você deseja acessar o seu perfil?</Label>
				<S.ResendCodeWrapper>
					<Button width="50%" margin="10px" type="button" onClick={validatePhone}>
						<Smartphone size={20} color="#fff" />&nbsp;
						SMS
					</Button>
					<Button width="50%" margin="10px" type="button" onClick={() => goToPasswordView(STEP_VIEW.PASSWORD_ACCESS_VIEW)}>
						<Unlock size={20} color="#fff"/>&nbsp;
						Senha
					</Button>
				</S.ResendCodeWrapper>
			</RenderIf>

			<RenderIf condition={currentView === STEP_VIEW.PASSWORD_ACCESS_VIEW}>
				<form onSubmit={login}>
					<InputWrapper hasError={password.hasError}>
						<Label data-required>Senha</Label>
						<Input id="password" type="password" value={password.value} onChange={changePassword} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>
					<RenderIf condition={requestAttempt > 2}>
						<ReCAPTCHA
							sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
							ref={captchaRef}
							onChange={($event) => setCaptchaToken($event || '')}
							onExpired={resetRecaptcha}
						/>
					</RenderIf>
					<Button width="100%" margin="2.25rem 0 0" loading={isLoading} disabled={password.value.length === 0 || password.hasError}>Acessar conta</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === STEP_VIEW.CODE_VIEW}>
				<form onSubmit={login}>
					<S.SmallText>Digite o código que você recebeu por SMS ou Whatsapp.</S.SmallText>
					<InputWrapper>
						<Label>Código</Label>
						<Input id="code" placeholder="000000" value={code.value} onChange={changeCode} />
					</InputWrapper>
					<RenderIf condition={requestAttempt > 2}>
						<ReCAPTCHA
							sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
							ref={captchaRef}
							onChange={($event) => setCaptchaToken($event || '')}
							onExpired={resetRecaptcha}
						/>
					</RenderIf>
					<Button
						width="100%"
						margin="2.25rem 0 0"
						loading={isLoading}
						disabled={code.value.length < CODE_SIZE || code.hasError}>Verificar código</Button>
				</form>
				<S.SmallTextLinkColored onClick={goToViewAccessPassword}>Acesse sua conta com senha</S.SmallTextLinkColored>
				<PhoneAuthResendCode hasOptions={false} resendCode={resendCode} />
			</RenderIf>

			<RenderIf condition={currentView === STEP_VIEW.EMAIL_VIEW}>
				<InputWrapper hasError={email.hasError}>
					<Label data-required>E-mail</Label>
					<Input id="email" placeholder="seunome@email.com" value={email.value} onChange={changeEmail} />
					<InputErrorMessage>Campo inválido</InputErrorMessage>
				</InputWrapper>

				<Button
					width="100%"
					margin="2.25rem 0 0"
					loading={isLoading}
					onClick={validateEmail}
					disabled={email.hasError || !email.value}>Validar e-mail</Button>
			</RenderIf>

			<RenderIf condition={currentView === STEP_VIEW.CODE_PASSWORD_VIEW}>
				<S.SmallText>Digite o código que você recebeu.</S.SmallText>
				<InputWrapper>
					<Label>Código</Label>
					<Input id="code" placeholder="000000" value={code.value} onChange={changeCode} />
				</InputWrapper>
				<RenderIf condition={requestAttempt > 2}>
					<ReCAPTCHA
						sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
						ref={captchaRef}
						onChange={($event) => setCaptchaToken($event || '')}
						onExpired={resetRecaptcha}
					/>
				</RenderIf>
				<Button
					width="100%"
					margin="2.25rem 0 0"
					loading={isLoading}
					onClick={validateCode}
					disabled={code.value.length < CODE_SIZE || code.hasError || (requestAttempt > 2 && captchaToken.length < 1)}>Verificar código</Button>
				<PhoneAuthResendCode hasOptions={false} resendCode={resendCodePassword} />
			</RenderIf>

			<RenderIf condition={currentView === STEP_VIEW.REGISTER_PASSWORD_VIEW}>
				<form onSubmit={validatePassword}>
					<InputWrapper hasError={newPassword.hasError}>
						<Label data-required>Senha</Label>
						<Input id="newPassword" type="password" value={newPassword.value} onChange={changeNewPassword} />
						<InputErrorMessage>Senha deve ter no mínimo 6 caracteres</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={confirmPassword.hasError}>
						<Label data-required>Confirmar senha</Label>
						<Input id="confirmPassword" type="password" value={confirmPassword.value} onChange={changeConfirmPassword} />
						<InputErrorMessage>As senhas são diferentes</InputErrorMessage>
					</InputWrapper>

					<Button width="100%" margin="2.25rem 0 0" loading={isLoading} disabled={passwordForm.invalid}>Salvar</Button>
				</form>
			</RenderIf>
		</>
	);
}

export default PhoneAuth;
