import styled from 'styled-components';
import Variables from 'styles/Variables';

export const Header = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 1rem;
	min-height: 40px;
`;

export const ReturnButton = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	font-size: 1rem;
	height: 2.5rem;
	line-height: 1;
	margin-right: 0.5rem;
	min-width: 2.5rem;
	justify-content: center;
	width: 2.5rem;
`;

export const Title = styled.p`
	font-size: 1.125rem;
	font-weight: 700;
`;

export const SmallText = styled.p`
	font-size: 0.875rem;
	margin-bottom: 1rem;
`;

export const SmallTextLink = styled.p`
	cursor: pointer;
	font-size: 0.875rem;

	&:hover { text-decoration: underline; }
`;

export const SmallTextLinkColored = styled.a`
	color: ${Variables.colors.main};
	cursor: pointer;
	display: block;
	font-size: 0.875rem;
	margin: 2.25rem auto 0;
	text-decoration: underline;
	width: fit-content;
`;

export const ResendCodeWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 1rem 1rem;
`;

export const ResendCodeTimer = styled.span`
	font-size: 0.875rem;
	padding: 0 0.5rem;
`;

export const ResendCode = styled.span`
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: 600;
`;

type ResendCodeProps = {
	disabled: boolean;
}
export const ResendCodeText = styled.span`
	color: ${Variables.colors.main};
	cursor: pointer;
	font-size: 0.875rem;
	text-decoration: underline;

	${({ disabled }: ResendCodeProps) => disabled && `
		cursor: not-allowed;
		filter: brightness(0);
		opacity: 0.25;
		pointer-events: none;
	`}
`;

export const TermsText = styled.p`
	font-size: 0.625rem;
	margin-top: 0.625rem;
	margin-bottom: 15px;

	a { font-weight: 600; }
`;

export const LoginAnnouncement = styled.div`
	background-color: #F3EDFF;
	border-radius: 0.5rem;
	margin-bottom: 1rem;
	padding: 0.75rem 1rem;
`;

export const LoginAnnouncementTitle = styled.p`
	font-weight: 600;
	margin-bottom: 0.25rem;
`;
export const LoginAnnouncementText = styled.p`
	font-size: 0.875rem;
`;

export const AuthFeedbackWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: center;
`

export const AuthFeedbackMessage = styled.div`
	font-size: 1.25rem;
    font-weight: 600;
    margin: 1.5rem 0;
    text-align: center;
`;

export const SupportContact = styled.div`
    align-items: center;
	border-radius: 0.5rem;
	display: flex;
	justify-content: center;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
	width: 100%;
`;

export const SupportContactWhatsapp = styled(SupportContact)`
    background-color: #D7E9D1;
	border: 3px solid #399C54;
`;

export const SupportContactMail = styled(SupportContact)`
    background-color: #FFEFCC;
	border: 3px solid #FFB300;
`;

export const SupportContactIcon = styled.img`
	height: 24px;
	margin-right: 0.75rem;
	width: 24px;
`;
