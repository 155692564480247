import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {RenderIf} from 'components/layout';
import {Text} from 'styles/Commons';
import UserContext from 'context/user';
import AuthService from 'services/AuthService';
import Modal from "../../layout/modal/Modal";
import {Button, Input, InputErrorMessage, InputWrapper, Label} from "../../../styles/Form";

const CreatePasswordUserPhone = () => {
	const location = useLocation();
	const [showWarning, setShowWarning] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [newPassword, setNewPassword] = useState({ value: '', hasError: false });
	const [confirmPassword, setConfirmPassword] = useState({ value: '', hasError: false });
	const [passwordForm, setPasswordForm] = useState({ invalid: true });
	const { state: userState, setState: setUserState } = useContext(UserContext);
	const [authType, setAuthType] = useState(process.env.REACT_APP_AUTH_TYPE);

	useEffect(() => {
		if (authType == 'PHONE') {
			hasPasswordUser();
		}
	}, [localStorage.getItem('USER_PHONE')]);

	useEffect(() => {
		const isFormInvalid = (!newPassword.value || newPassword.hasError || !confirmPassword.value || confirmPassword.hasError);
		setPasswordForm({ invalid: isFormInvalid });
	}, [newPassword, confirmPassword]);

	const validatePassword = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);
		const payload = {
			password: newPassword.value,
			confirmPassword: confirmPassword.value,
		}
		const userLogged = localStorage.getItem('USER_PHONE');
		if (userLogged) {
			const split = userLogged.split('|||');
			AuthService.registerPassword(payload, split[2], split[1])
				.then(({ data }: any) => {
					toast(data.message, { type: toast.TYPE.SUCCESS });
					setShowWarning(false);
				})
				.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }))
				.finally(() => setIsLoading(() => false));
		}
	};

	const changeNewPassword = (ev: any) => {
		ev.persist();
		setNewPassword(() => ({
			hasError: ev.target.value.length < 6,
			value: ev.target.value
		}));
	};

	const changeConfirmPassword = (ev: any) => {
		ev.persist();
		setConfirmPassword(() => ({
			hasError: ev.target.value.length < 6 || newPassword.value !== ev.target.value,
			value: ev.target.value
		}));
	};

	const hasPasswordUser = () => {
		const userPhone = localStorage.getItem('USER_PHONE');
		if (userPhone && userPhone.length > 8) {
			const phone = userPhone.split('|||')[0];
			setIsLoading(true);
			AuthService.hasUserPassword(phone)
				.finally(() => setIsLoading(false))
				.then(({data}) => setShowWarning(!data));
		}
	}

	return (
		<RenderIf condition={userState.isLogged}>
			<Modal isOpen={authType == 'PHONE' && showWarning} title="Cadastre sua senha" showClose={false}>
				<Text fontSize={0.7} style={{marginBottom: '20px'}}>Este será um método de acesso alternativo</Text>
				<>
					<form onSubmit={validatePassword}>
						<InputWrapper hasError={newPassword.hasError}>
							<Label data-required>Senha</Label>
							<Input id="newPassword" type="password" value={newPassword.value} onChange={changeNewPassword} />
							<InputErrorMessage>Senha deve ter no mínimo 6 caracteres</InputErrorMessage>
						</InputWrapper>

						<InputWrapper hasError={confirmPassword.hasError}>
							<Label data-required>Confirmar senha</Label>
							<Input id="confirmPassword" type="password" value={confirmPassword.value} onChange={changeConfirmPassword} />
							<InputErrorMessage>As senhas são diferentes</InputErrorMessage>
						</InputWrapper>

						<Button width="100%" margin="2.25rem 0 0" loading={isLoading} disabled={passwordForm.invalid}>Salvar</Button>
					</form>
				</>
			</Modal>
		</RenderIf>
	)
}

export default CreatePasswordUserPhone;
